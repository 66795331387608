<template>
  <div class="rule-block d-flex">
    <div class="rule-operator pt-3">
      <span v-if="showGroupOperator()">{{ groupOperator }}</span>
    </div>
    <div class="align-items-center rule-box">
      <div class="row">
        <div class="col-12">
          <div
            :class="[
              'pl-2 pb-2 pt-2 pr-0',
              'rule-box-cnt',
              'form-inline',
              'std-border',
              'rounded',
              'depth-' + ruleDepth(),
              query.error ? 'rule-error' : ''
            ]"
            v-if="isRule"
          >
            <div class="px-1 mx-0 row w-100">
              <div class="px-1 col-12 form-inline">
                <div class="px-1 mb-1">
                  <!-- <b-form-select
                    class="form-control"
                    v-model="query.ruleId"
                    @change="ruleIdChanged"
                    :options="baseOptionsForMBC"
                  ></b-form-select> -->
                  <multiselect
                    v-model="ruleIdObj"
                    track-by="value"
                    label="text"
                    :options="baseOptionsForMBC"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select"
                    @close="ruleIdChanged"
                    @open="turnAutoCompleteOff()"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.text }}</span>
                    </template>
                  </multiselect>
                </div>
                <div class="px-1 mb-1" v-if="canShowMLFilter(chosenRule)">
                  <multiselect
                    v-model="query.actionTaken[0]"
                    track-by="value"
                    label="label"
                    :options="actionTakenOperandsForML"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                    @close="setFitTypeDependents"
                    @open="turnAutoCompleteOff()"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values }"
                      v-if="values.length"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('option') }} selected
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex">
                        <div
                          class="option-text"
                          :id="`at-${props.option.value}`"
                        >
                          {{ props.option.label }}
                        </div>
                      </div>
                      <b-popover
                        :target="`at-${props.option.value}`"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="props.option.description"
                      >
                        {{ props.option.description }}
                      </b-popover>
                    </template>
                  </multiselect>
                  <multiselect
                    v-model="query.outputType"
                    track-by="value"
                    label="label"
                    :options="outputTypeOperandsForML"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select ml-2"
                    @close="setOperandsForML"
                    @open="turnAutoCompleteOff()"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>
                  </multiselect>
                  <!-- <select
                    class="form-control ml-2"
                    v-model="query.outputType"
                    @change="setOperandsForML"
                  >
                    <option value="null" selected disabled>
                      Please Select
                    </option>
                    <option
                      v-for="(at, index) in outputTypeOperandsForML"
                      :key="index"
                      :value="at.value"
                      >{{ at.label }}</option
                    >
                  </select> -->
                </div>
                <div
                  class="px-1 mb-1"
                  v-else-if="
                    chosenRule && chosenRule.label == 'Select Institutions'
                  "
                >
                  <b-row class="ml-auto">
                    <multiselect
                      v-model="sourceObj"
                      track-by="value"
                      label="label"
                      :options="selectInstitutionOptions"
                      :multiple="false"
                      :close-on-select="true"
                      :showLabels="false"
                      placeholder="Select one"
                      class="simple-select group-select form-control rounded advanced-query-select value-multi-select ml-2"
                      @close="selectInstitution()"
                      @open="turnAutoCompleteOff()"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option-text">{{
                          props.option.label
                        }}</span>
                      </template>
                    </multiselect>
                    <!-- <select
                      v-model="query.source"
                      @change="clearResult()"
                      class="form-control"
                    >
                      <option value="null" selected disabled>
                        Please Select
                      </option>
                      <option value="include">Include</option>
                      <option value="exclude">Exclude</option>
                    </select> -->
                    <b-button
                      v-if="
                        query.source && query.value && query.value.length == 0
                      "
                      class="mx-3 px-3 my-1 float-right"
                      pill
                      size="sm"
                      @click="showInstitutionsModal(query.id)"
                      variant="dark"
                      ><b>Select FI</b></b-button
                    >
                    <b-button
                      v-if="
                        query.source && query.value && query.value.length > 0
                      "
                      class="mx-3 px-3 my-1 float-right"
                      pill
                      size="sm"
                      @click="showInstitutionsModal(query.id)"
                      variant="dark"
                    >
                      <b>{{ query.value.length }} Institutions Selected</b>
                    </b-button>
                    <b-button
                      v-if="
                        query.source && query.value && query.value.length == 0
                      "
                      class="mx-3 px-3 my-1 float-right"
                      pill
                      size="sm"
                      @click="showUploadInstitutionsModal(query.id)"
                      variant="dark"
                      ><b>Upload FIs</b></b-button
                    >
                    <SelectInstitutionModal
                      :query="query"
                      :rule="this"
                      :isMortgageLender="true"
                    ></SelectInstitutionModal>
                    <UploadInstitutionModal
                      :query="query"
                      :rule="this"
                    ></UploadInstitutionModal>
                  </b-row>
                </div>
                <div
                  class="px-1 mb-1"
                  v-else-if="chosenRule && chosenRule.label == 'Job Search'"
                >
                  <b-row class="ml-auto">
                    <label class="mx-3" for="input-1">Search Criteria</label>
                    <input
                      class="form-control rounded mt-1"
                      v-model="query.value[0]"
                      @focus="disableClone()"
                      @keyup="clearResult()"
                      @blur="fetchCount()"
                    />
                    <label class="mx-3" for="input-2">From Date</label>
                    <input
                      type="date"
                      class="form-control rounded mt-1"
                      v-model="query.value[1]"
                      @focus="disableClone()"
                      @keyup="clearResult()"
                      @blur="fetchCount()"
                    />
                    <span class="m-2" v-if="query.loading">
                      <i class="fa fa-spinner fa-spin"></i>
                    </span>
                    <b-button
                      class="m-2 px-3 float-right"
                      pill
                      size="sm"
                      v-b-modal="'jobSearch' + query.id"
                      v-if="!query.loading && !hideResultCount"
                      variant="dark"
                      ><b>View Results</b></b-button
                    >
                    <JobSearch
                      :query="query"
                      :rule="this"
                      :isMbc="true"
                    ></JobSearch>
                  </b-row>
                </div>

                <div
                  class="px-1 mb-1"
                  v-else-if="chosenRule && chosenRule.label == 'Site Search'"
                >
                  <b-row class="ml-auto">
                    <label class="mx-3" for="input-1">Search Criteria</label>
                    <input
                      class="form-control rounded mt-1"
                      v-model="query.value[0]"
                      @focus="disableClone()"
                      @keyup="clearResult()"
                      @blur="fetchCount()"
                      @keyup.enter="fetchSiteResults()"
                      ref="siteKeyword"
                    />
                    <span class="m-2" v-if="query.loading">
                      <i class="fa fa-spinner fa-spin"></i>
                    </span>
                    <b-button
                      class="m-2 px-3 float-right"
                      pill
                      size="sm"
                      v-b-modal="`siteSearch_${query.id}`"
                      v-if="!query.loading && !hideResultCount"
                      variant="dark"
                      ><b>View Results</b></b-button
                    >
                    <SiteSearch
                      :query="query"
                      :rule="this"
                      :isMbc="true"
                    ></SiteSearch>
                  </b-row>
                </div>
                <div
                  class="px-1 mb-1"
                  v-else-if="chosenRule && chosenRule.label == 'Contact Search'"
                >
                  <b-row class="ml-auto">
                    <label class="mx-3" for="input-1"
                      >Title Search Criteria</label
                    >
                    <input
                      class="form-control rounded mt-1"
                      v-model="query.value[0]"
                      @focus="disableClone()"
                      @keyup="clearResult()"
                      @blur="fetchCount()"
                      @keyup.enter="fetchContactResults()"
                      ref="contactKeyword"
                    />
                    <span class="m-2" v-if="query.loading">
                      <i class="fa fa-spinner fa-spin"></i>
                    </span>
                    <b-button
                      class="m-2 px-3 float-right"
                      pill
                      size="sm"
                      v-b-modal="`contactSearch_${query.id}`"
                      v-if="!query.loading && !hideResultCount"
                      variant="dark"
                      ><b>View Results</b></b-button
                    >
                    <span v-if="!query.loading && !hideResultCount"
                      ><i class="pl-1"
                        >(Results are the number of FIs with employee titles
                        <br />that match criteria.)</i
                      ></span
                    >
                    <ContactSearch
                      :query="query"
                      :rule="this"
                      :isMbc="true"
                    ></ContactSearch>
                  </b-row>
                </div>

                <div class="px-1 mb-1" v-else-if="query.ruleId">
                  <div
                    @click="showTreeModal"
                    class="metric-select-input form-control rounded"
                    v-if="isTechnographicsRule"
                  >
                    {{ query.operand ? query.operandLabel : 'Select one' }}
                    <i
                      class="fa fa-external-link link-text pl-2"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <multiselect
                    v-model="firmoOperand"
                    track-by="id"
                    label="label"
                    :options="ruleOperands"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select "
                    @close="setFirmoOperand"
                    @open="turnAutoCompleteOff()"
                    v-else-if="isFirmographicsRule"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>

                    <template slot="option" slot-scope="props">
                      <div class="d-flex">
                        <div class="option-text" :id="`f-${props.option.id}`">
                          {{ props.option.label }}
                        </div>
                      </div>
                      <b-popover
                        :target="`f-${props.option.id}`"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="props.option.description"
                      >
                        {{ props.option.description }}
                      </b-popover>
                    </template>
                  </multiselect>
                  <select
                    class="form-control operand-select"
                    v-model="query.operand"
                    @change="setDependants"
                    v-else
                  >
                    <option value="null" selected disabled> Select one</option>
                    <option
                      v-for="(operand, index) in ruleOperands"
                      :key="index"
                      :value="operand.id"
                      >{{ operand.label }}</option
                    >
                  </select>
                </div>

                <div class="px-1 mb-1" v-if="hasChildOperand()">
                  <select
                    class="form-control child-operand-select"
                    v-model="query.childOperand"
                    @change="setChildDependants"
                  >
                    <option value="null" selected disabled>
                      Select one
                    </option>
                    <option
                      v-for="(childOperand, index) in ruleChildOperands"
                      :key="index"
                      :value="childOperand.id"
                      >{{ childOperand.label }}</option
                    >
                  </select>
                </div>
                <div class="px-1 mb-1" v-if="isFirmographicsWithActionTaken()">
                  <multiselect
                    v-model="query.elementOption[0]"
                    track-by="value"
                    label="label"
                    :options="elementOptions"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                    @close="setFitTypeDependents"
                    @open="turnAutoCompleteOff()"
                    v-if="isConformingLoanLimit"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values }"
                      v-if="values.length"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('option') }} selected
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex">
                        <div
                          class="option-text"
                          :id="`${query.id}-atf-${props.option.value}`"
                        >
                          {{ props.option.label }}
                        </div>
                      </div>
                      <b-popover
                        :target="`${query.id}-atf-${props.option.value}`"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="props.option.description"
                      >
                        {{ props.option.description }}
                      </b-popover>
                    </template>
                  </multiselect>

                  <multiselect
                    v-model="query.elementOption"
                    track-by="value"
                    label="label"
                    :options="elementOptions"
                    :multiple="true"
                    :close-on-select="false"
                    :showLabels="false"
                    :placeholder="
                      emptyValue(query.elementOption)
                        ? 'Select one or more'
                        : ''
                    "
                    class="simple-select group-select form-control rounded advanced-query-select eo-multi-select"
                    @close="setFitTypeDependents"
                    @open="turnAutoCompleteOff()"
                    v-else
                  >
                    <!-- <template slot="caret"> -->
                    <span
                      class="arrow"
                      slot="caret"
                      slot-scope="{ toggle }"
                      @mousedown.prevent="toggle"
                    >
                      <i class="fa fa-angle-double-down fa-lg double-arrow"></i>
                    </span>
                    <!-- </template> -->
                    <template
                      slot="selection"
                      slot-scope="{ values }"
                      v-if="values.length"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('option') }} selected
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex">
                        <div
                          class="option-text"
                          :id="`eo-${props.option.value}`"
                        >
                          {{ props.option.label }}
                        </div>
                      </div>
                      <b-popover
                        :target="`eo-${props.option.value}`"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="props.option.description"
                      >
                        {{ props.option.description }}
                      </b-popover>
                    </template>
                  </multiselect>
                </div>
                <div class="px-1 mb-1" v-if="isFirmographicsWithActionTaken()">
                  <multiselect
                    :id="query.id + '_at'"
                    v-model="query.actionTaken[0]"
                    track-by="value"
                    label="label"
                    :options="actionTakenOperands"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                    @close="setFitTypeDependents"
                    @open="turnAutoCompleteOff()"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values }"
                      v-if="values.length"
                    >
                      {{ values.length }}
                      {{ values.length | pluralize('option') }} selected
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="d-flex">
                        <div
                          class="option-text"
                          :id="`${query.id}-atf-${props.option.value}`"
                        >
                          {{ props.option.label }}
                        </div>
                      </div>
                      <b-popover
                        :target="`${query.id}-atf-${props.option.value}`"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="props.option.description"
                      >
                        {{ props.option.description }}
                      </b-popover>
                    </template>
                  </multiselect>
                  <multiselect
                    v-model="query.outputType"
                    track-by="value"
                    label="label"
                    :options="outputTypeOperands"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    placeholder="Select one"
                    class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                    @open="turnAutoCompleteOff()"
                    @close="setFitTypeDependents"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option-text">{{ props.option.label }}</span>
                    </template>
                  </multiselect>
                  <!--
                  <select
                    class="form-control ml-2"
                    v-model="query.outputType"
                    @change="setFitTypeDependents"
                  >
                    <option value="null" selected disabled>
                      Please Select
                    </option>
                    <option
                      v-for="(at, index) in outputTypeOperands"
                      :key="index"
                      :value="at.value"
                      >{{ at.label }}</option
                    >
                  </select> -->
                </div>
                <!-- Vendor fit not applicable for MBC - so not changing select to multiselect-->
                <!-- <div class="px-1 mb-1" v-if="isVendorFit()">
                  <div class="d-inline px-2 font-weight-bold">
                    AND
                  </div>
                  <select
                    class="form-control"
                    v-model="query.fitType"
                    @change="setFitTypeDependents"
                  >
                    <option value="null" selected disabled>
                      Please Select
                    </option>
                    <option
                      v-for="(fitType, index) in fitTypeOperands"
                      :key="index"
                      :value="fitType.id"
                      >{{ fitType.label }}</option
                    ></select
                  >
                </div> -->

                <div class="px-1 mb-1" v-if="hasOperand">
                  <div v-if="ruleOperators.length === 1">
                    <i class="fa fa-arrow-right"></i>
                  </div>
                  <div v-else>
                    <multiselect
                      v-model="operatorObj"
                      track-by="id"
                      label="label"
                      :options="ruleOperators"
                      :multiple="false"
                      :close-on-select="true"
                      :showLabels="false"
                      placeholder="Select one"
                      class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                      @close="setOperatorDependants"
                      @open="turnAutoCompleteOff()"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option-text">{{
                          props.option.label
                        }}</span>
                      </template>
                    </multiselect>
                    <!-- <select
                      class="form-control operator-select"
                      v-model="query.operator"
                      @change="setOperatorDependants"
                    >
                      <option
                        v-for="(operator, index) in ruleOperators"
                        :key="index"
                        :value="operator.id"
                        >{{ operator.label }}</option
                      >
                    </select> -->
                  </div>
                </div>

                <div
                  class="px-1 mb-1"
                  v-if="query.operator && ruleForOptionType"
                >
                  <div v-if="ruleForOptionType.type === 'number'">
                    <div v-if="isBetweenOperator">
                      <input
                        type="number"
                        v-model="query.value[0]"
                        class="form-control rounded"
                        pattern="[0-9.]+"
                        @focus="disableClone()"
                        @blur="fetchCount()"
                        @keyup="clearResult()"
                      />
                      <input
                        type="number"
                        v-model="query.value[1]"
                        class="ml-2 form-control rounded"
                        pattern="[0-9.]+"
                        @focus="disableClone()"
                        @blur="fetchCount()"
                        @keyup="clearResult()"
                      />
                    </div>
                    <div v-else>
                      <input
                        type="number"
                        v-model="query.value[0]"
                        class="form-control rounded"
                        pattern="[0-9.]+"
                        @focus="disableClone()"
                        @blur="fetchCount()"
                        @keyup="clearResult()"
                      />
                    </div>
                  </div>

                  <div v-else-if="ruleForOptionType.type === 'select'">
                    <multiselect
                      v-model="singleValueObj"
                      track-by="id"
                      label="label"
                      :options="ruleOptions"
                      :multiple="false"
                      :close-on-select="true"
                      :showLabels="false"
                      placeholder="Select one"
                      class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
                      @open="turnAutoCompleteOff()"
                      @close="fetchResultCount()"
                      v-if="showSingleSelect()"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option-text">{{
                          props.option.label
                        }}</span>
                      </template>
                    </multiselect>
                    <!-- <select
                      class="form-control value-select"
                      v-model="query.value[0]"
                      @change="fetchResultCount()"
                      v-if="showSingleSelect()"
                    >
                      <option
                        v-for="(option, index) in ruleOptions"
                        :key="index"
                        :value="option.id"
                        >{{ option.label }}</option
                      >
                    </select> -->
                    <multiselect
                      v-else
                      v-model="query.value"
                      track-by="id"
                      label="label"
                      :options="ruleOptions"
                      :multiple="true"
                      :close-on-select="false"
                      :showLabels="false"
                      :placeholder="
                        emptyValue(query.value) ? 'Select one or more' : ''
                      "
                      class="simple-select group-select form-control rounded advanced-query-select eo-multi-select"
                      @open="disableClone()"
                      @close="fetchResultCount()"
                    >
                      <span
                        class="arrow"
                        slot="caret"
                        slot-scope="{ toggle }"
                        @mousedown.prevent="toggle"
                      >
                        <i
                          class="fa fa-angle-double-down fa-lg double-arrow"
                        ></i>
                      </span>
                      <template
                        slot="selection"
                        slot-scope="{ values }"
                        v-if="values.length"
                      >
                        {{ values.length }}
                        {{ values.length | pluralize('option') }} selected
                      </template>
                    </multiselect>
                  </div>

                  <div v-else>
                    <input
                      type="text"
                      v-model="query.value[0]"
                      class="form-control rounded"
                      @focus="disableClone()"
                      @blur="fetchCount()"
                      @keyup="clearResult()"
                    />
                  </div>
                </div>

                <div class="close-icon">
                  <i
                    :class="[
                      enableClone ? 'enabled' : 'disabled',
                      'fa fa-clone pr-2 clone-rule-icon'
                    ]"
                    @click="cloneRule"
                  ></i>
                  <i class="fa fa-times-circle fa-lg" @click="removeRule"></i>
                </div>
              </div>
            </div>

            <div class="px-1 mb-1 row w-100" v-if="canShowMLFilter(chosenRule)">
              <div
                class="px-1 mb-1 col-10 ml-4 mt-2 filter-depth form-inline std-border rounded filter-section"
              >
                <div class="pt-1 pb-2 px-2 mt-1 mb-1">
                  <span class="pr-2">
                    <b>Mortgage Loan/Application Filter</b></span
                  >
                  <c-switch
                    type="text"
                    variant="success"
                    on="Yes"
                    off="No"
                    :pill="true"
                    size="md"
                    :checked="query.filterOn"
                    @change="toggleShowFilters($event)"
                  />
                </div>
                <div
                  class="w-100"
                  v-if="
                    query.filterOn &&
                      chosenRule &&
                      chosenRule.label == 'Mortgage Lending'
                  "
                >
                  <FilterGroup
                    :query="query"
                    :rootQuery="rootQuery"
                    :filters="chosenRule.filters"
                    renderedIn="Query"
                    @updateQuery="updateQuery"
                    @filterUpdated="filterUpdated"
                    @clearResult="clearResult"
                    @addFilter="addFilter"
                  ></FilterGroup>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <query-group
              :query="query"
              :rules="rules"
              @updateQuery="updateQuery"
              @removeGroup="removeGroup"
              @clearResult="clearResult"
            ></query-group>
          </div>
        </div>
      </div>
    </div>
    <div class="rule-count pt-3">
      <span v-if="query.loading">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
      <span v-else-if="!hideResultCount">
        {{ query.count | numberFormat }}
        <span class="d-none">{{ resultCount }}</span>
      </span>
    </div>

    <technographics-modal
      :queryID="query.id"
      :selectedServiceID="query.operand"
      :isMortgageLender="true"
      @updateService="serviceUpdate"
    ></technographics-modal>
  </div>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '@/plugins/events.js'
// ui components
import QueryGroup from './QueryGroup'
import FilterGroup from './FilterGroup'
import JobSearch from './JobSearch'
import SiteSearch from './SiteSearch'
import ContactSearch from './ContactSearch'
import SelectInstitutionModal from '../../core/components/SelectInstitutionModal.vue'
import UploadInstitutionModal from '@/modules/core/components/UploadInstitutionModal.vue'
import Multiselect from 'vue-multiselect'
import TechnographicsModal from '@/modules/core/components/TechnographicsModal.vue'
// specific utilities
import QueryTransformer from '../helpers/query.transformer.js'
// general utilities
import { findDeep } from 'deepdash'
import _ from 'lodash'
import cSwitch from '@/components/Switch'
import deepClone from '../../../utilities'

export default {
  name: 'QueryRuleForMBC',
  components: {
    QueryGroup,
    JobSearch,
    SiteSearch,
    ContactSearch,
    SelectInstitutionModal,
    Multiselect,
    TechnographicsModal,
    UploadInstitutionModal,
    cSwitch,
    FilterGroup
  },
  props: {
    rules: {
      type: Array
    },
    query: {
      type: Object
    },
    rootQuery: {
      type: Object
    },
    groupOperator: {
      type: String
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      resultCount: null,
      enableClone: true,
      firmoOperand: null,
      ruleIdObj: null,
      operatorObj: null,
      singleValueObj: null,
      sourceObj: null,
      selectInstitutionOptions: [
        { label: 'Include', value: 'include' },
        { label: 'Exclude', value: 'exclude' }
      ],

      //showFilters: false,
      basicOperators: [
        'equal',
        'less',
        'less or equal',
        'greater',
        'greater or equal',
        'between',
        'not between'
      ]
    }
  },
  mounted() {
    if (this.isFirmographicsRule && this.query.operand)
      this.firmoOperand = this.chosenRule.children.find(
        child => child.id === this.query.operand
      )
    if (this.query.ruleId) {
      this.ruleIdObj = this.baseOptionsForMBC.find(
        opt => opt.value == this.query.ruleId
      )
    }
    if (this.query.operator) {
      this.operatorObj = this.ruleOperators.find(
        opt => opt.id == this.query.operator
      )
    }
    if (this.showSingleSelect() && this.query.value[0] != null) {
      this.singleValueObj = this.ruleOptions.find(
        opt => opt.id == this.query.value[0]
      )
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      queryElements: state => state.queryElements
    }),
    ...mapGetters('AdvancedQuery', {
      baseOptionsForMBC: 'baseQueryOptionsForMBC',
      technographicRules: 'technographicRules',
      isMortgageLender: 'isMortgageLender'
    }),
    isFirmographicsRule() {
      return this.chosenRule && this.chosenRule.label === 'Firmographics'
    },
    invalidCriteria() {
      const pattern = new RegExp(/[~`!()#$%^&*+=\-[\]\\';,/{}|\\":<>?]/) //unacceptable chars
      return pattern.test(this.query.value[0])
    },
    isRule() {
      return this.query.type === 'rule'
    },
    basicOperandRule() {
      return {
        operators: this.basicOperators,
        children: [],
        type: 'number'
      }
    },
    chosenRule() {
      if (!this.query.ruleId) {
        return null
      }
      return this.rules.find(rule => rule.id === this.query.ruleId)
    },
    ruleOperands() {
      if (!this.chosenRule) {
        return []
      }
      return this.chosenRule.children.map(rule => {
        return {
          id: rule.id,
          label: rule.label,
          element_id: rule.element_id,
          description: rule.description
        }
      })
    },
    chosenOperandRule() {
      if (!this.query.operand) {
        return null
      }
      if (this.isTechnographicsRule) {
        return this.matchingTechnographicOperandRule
      } else {
        return this.chosenRule.children.find(
          operand => operand.id === this.query.operand
        )
      }
    },
    matchingTechnographicOperandRule() {
      let matchingService = null
      this.technographicRules.every(rule => {
        matchingService = findDeep(
          rule.children,
          function(bs) {
            return parseInt(bs.element_id) === this.query.operand
          }.bind(this),
          {
            childrenPath: 'children'
          }
        )
        return matchingService ? false : true
      })

      return matchingService ? matchingService.value : {}
    },
    ruleChildOperands() {
      if (!this.hasChildOperand()) {
        return []
      }
      return this.chosenOperandRule.children.map(operand => {
        return { id: operand.id, label: operand.label }
      })
    },
    fitTypeOperands() {
      if (!this.isVendorFit()) {
        return []
      }

      return this.chosenChildOperandRule.operators.vendor_fit.map(operand => {
        return { id: operand, label: operand }
      })
    },
    actionTakenOperandsForML() {
      if (!this.isMortgageLending()) {
        return []
      }
      return this.chosenRule.operators.action_taken
    },
    outputTypeOperandsForML() {
      if (!this.isMortgageLending()) {
        return []
      }
      return this.chosenRule.operators.output_type
    },
    actionTakenOperands() {
      if (!this.isFirmographicsWithActionTaken()) {
        return []
      }
      return this.chosenOperandRule.operators.action_taken
    },

    outputTypeOperands() {
      if (!this.isFirmographicsWithActionTaken()) {
        return []
      }
      return this.chosenOperandRule.operators.output_type
    },

    elementOptions() {
      if (!this.isFirmographicsWithActionTaken()) {
        return []
      }
      return this.chosenOperandRule.operators.element_options
    },
    isConformingLoanLimit() {
      return this.chosenOperandRule.element_property === 'conforming_loan_limit'
    },
    chosenChildOperandRule() {
      if (!this.query.childOperand) {
        return null
      }
      return this.chosenOperandRule.children.find(
        childOperand => childOperand.id === this.query.childOperand
      )
    },
    ruleOperators() {
      if (!this.hasOperand) {
        return []
      }
      let operandRule = this.isMortgageLending()
        ? this.chosenRule
        : this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule

      if (operandRule) {
        let operators = this.isVendorFit()
          ? operandRule.operators.operators
          : this.isFirmographicsWithActionTaken() || this.isMortgageLending()
          ? operandRule.operators.operators
          : operandRule.operators

        if (this.isNestedOperator(operators)) {
          return operators.map(operator => {
            return { id: operator.label, label: operator.label }
          })
        } else {
          return operators.map(operator => {
            return { id: operator, label: operator }
          })
        }
      } else {
        return []
      }
    },
    chosenOperatorRule() {
      if (!this.query.operator) {
        return null
      }
      let operandRule = this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
      let operators = operandRule.operators
      if (this.isNestedOperator(operators)) {
        return operators.find(
          operator => operator.label === this.query.operator
        )
      } else {
        return this.chosenOperandRule
      }
    },
    multiselectRuleOptions() {
      return this.ruleOptions.map(option => option.id)
    },
    ruleOptions() {
      if (!this.query.operator) {
        return []
      }
      // array is temporary, needs to replaced with proper input field
      let options = this.chosenOperatorRule.options || []
      if (typeof options[0] === 'object') {
        return options.map(option => {
          return { id: option.value, label: option.label }
        })
      } else {
        return options.map(option => {
          return { id: option, label: option }
        })
      }
    },
    hasOperand() {
      return this.hasChildOperand()
        ? this.isVendorFit()
          ? this.query.fitType
          : this.query.childOperand
        : this.query.operand
    },
    isBetweenOperator() {
      return ['between', 'not between'].includes(this.query.operator)
    },
    ruleForOptionType() {
      return this.isMortgageLending()
        ? this.chosenRule
        : this.hasChildOperand()
        ? this.chosenChildOperandRule
        : this.chosenOperandRule
    },
    hideResultCount() {
      return this.query.count === null
    },
    isTechnographicsRule() {
      return this.chosenRule.label === 'Technographics'
    }
  },
  methods: {
    selectInstitution() {
      this.query.source = this.sourceObj.value
      this.clearResult()
    },
    setFirmoOperand() {
      if (this.firmoOperand) this.query.operand = this.firmoOperand.id
      this.setDependants()
    },
    emptyValue(arrayValue) {
      return Array.isArray(arrayValue)
        ? arrayValue.length === 0
        : [undefined, ''].includes(arrayValue)
    },
    setOperandsForML() {
      if (this.query.outputType) {
        this.query.operand = this.query.outputType.value
        this.query.outputTypeLabel = this.query.outputType.label
      }
      this.query.operator = null
      this.query.value = []
    },
    canShowMLFilter(chosenRule) {
      return chosenRule && chosenRule.label == 'Mortgage Lending'
    },
    isMortgageLending() {
      return this.chosenRule && this.chosenRule.label == 'Mortgage Lending'
    },
    toggleShowFilters(event) {
      //this.showFilters = event
      this.query.filterOn = event
      this.query.filters = [
        {
          id: this.query.id + '_1',
          ruleId: this.query.id,
          filterId: null,
          type: 'filter',
          childOperand: null,
          value: [],
          count: null,
          error: false,
          operand: null,
          operator: null,
          loading: false
        }
      ]
    },
    turnAutoCompleteOff() {
      document
        .querySelectorAll('.multiselect__input')
        .forEach(e => e.setAttribute('data-form-type', 'other'))
    },
    disableClone() {
      this.turnAutoCompleteOff()
      this.enableClone = false
    },
    addFilter() {
      let query = deepClone(this.query)
      query.filters.push({
        id: this.generateNewFilterId(query),
        ruleId: query.id,
        filterId: null,
        type: 'filter',
        operator: null,
        operand: null,
        childOperand: null,
        value: [],
        count: null,
        error: false,
        loading: false
      })
      this.$emit('addFilter', query)
    },
    generateNewFilterId(query) {
      let len = query.filters.length
      if (len > 0) {
        let oldId = query.filters[len - 1].id.split('_').pop()
        let newId = parseInt(oldId) + 1
        return query.id + '_' + newId
      } else {
        return query.id + '_' + 1
      }
    },
    updateQuery(query) {
      this.$emit('updateQuery', query)
    },
    clearResult() {
      this.$emit('clearResult')
    },
    removeGroup(groupId) {
      this.$emit('removeGroup', groupId)
    },
    cloneRule() {
      this.$emit('cloneRule', this.query)
    },
    removeRule() {
      this.$emit('removeRule', this.query.id)
    },
    filterUpdated() {
      this.fetchCount()
    },
    hasChildOperand() {
      return (
        this.chosenOperandRule &&
        this.chosenOperandRule.children &&
        this.chosenOperandRule.children.length > 0
      )
    },
    isVendorFit() {
      return (
        this.chosenChildOperandRule &&
        this.chosenChildOperandRule.operators.vendor_fit
      )
    },
    isFirmographicsWithActionTaken() {
      return (
        this.chosenOperandRule &&
        this.chosenOperandRule.operators &&
        this.chosenOperandRule.operators.action_taken
      )
    },
    isNestedOperator(operators) {
      return operators.length > 0 && typeof operators[0] === 'object'
    },
    setDependants() {
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
      this.query.actionTaken = []
      this.query.outputType = null
      this.query.outputTypeLabel = null
      this.query.elementOption = []
      if (this.hasChildOperand()) {
        this.query.source = null
      } else {
        this.query.source = this.chosenOperandRule.source
        this.query.cuSource = this.chosenOperandRule.cu_source
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
      }
      this.query.operandLabel = this.ruleOperands.find(
        o => o.id === this.query.operand
      ).label
      this.$emit('clearResult')
    },
    setChildDependants() {
      this.query.fitType = null
      this.query.operator = null
      this.query.count = null
      this.query.actionTaken = []
      this.query.outputType = null
      this.query.outputTypeLabel = null
      this.query.elementOption = []
      this.query.value = []
      if (this.chosenChildOperandRule) {
        this.query.source = this.chosenChildOperandRule.source
        this.query.cuSource = this.chosenChildOperandRule.cu_source
        this.query.operator = this.ruleOperators.length
          ? this.ruleOperators[0].id
          : null
      }
      this.$emit('clearResult')
    },
    setFitTypeDependents() {
      if (this.query.outputType && this.isFirmographicsWithActionTaken())
        this.query.outputTypeLabel = this.query.outputType.label

      this.query.operator = null
      this.query.value = []
      if (this.query.fitType) {
        this.query.operator = this.ruleOperators[0].id
      }
      this.$emit('clearResult')
    },
    ruleIdChanged() {
      if (this.ruleIdObj) this.query.ruleId = this.ruleIdObj.value
      this.firmoOperand = null
      this.operatorObj = null
      this.singleValueObj = null
      this.sourceObj = null
      this.query.operand = null
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.value = []
      this.query.count = null
      this.query.source = null
      this.query.cuSource = null
      this.query.operandLabel = null
      this.query.fiType = null
      this.query.actionTaken = []
      this.query.outputType = null
      this.query.outputTypeLabel = null
      this.query.elementOption = []
      this.query.filterOn = false
      this.query.filters = [
        {
          id: '1_1_1',
          ruleId: '1_1',
          filterId: null,
          type: 'filter',
          childOperand: null,
          value: [],
          count: null,
          error: false,
          operand: null,
          operator: null,
          loading: false
        }
      ]
      this.$emit('clearResult')
    },
    showGroupOperator() {
      return this.index !== 0
    },
    ruleDepth() {
      return (this.query.id.split('_').length - 1) % 4
    },
    canFetchCount() {
      if (this.isBetweenOperator) {
        return this.query.value[0] && this.query.value[1]
      } else {
        return !this.emptyValue(this.query.value[0])
      }
    },
    fetchResultCount() {
      if (this.showSingleSelect() && this.singleValueObj) {
        this.query.value[0] = this.singleValueObj.id
      }
      this.$emit('clearResult')
      this.fetchCount()
    },
    fetchCount() {
      this.enableClone = true
      if (this.canFetchCount()) {
        this.query.loading = true
        let qt = new QueryTransformer(this.rootQuery)

        this.$http
          .get('/api/advanced_query/count', {
            params: {
              query: {
                element: qt.transformToElement(this.query),
                criteria: qt.transformToCriteria(this.query)
              },
              is_mbc: this.isMortgageLender
            },
            handleErrors: true
          })
          .then(
            res => {
              if (!_.isEmpty(res.data.errors)) {
                let error = res.data.errors['job_search']
                  ? res.data.errors['job_search']
                  : res.data.errors['site_search']
                  ? res.data.errors['site_search']
                  : res.data.errors['contact_search']
                let message = error.includes('Lexical')
                  ? 'Please make sure the reserved characters(+ - = && || > < ! ( ) { } [ ] ^ " ~ * ? : \\ /) are used appropriately.'
                  : 'Please enter a valid search criteria.'
                this.$toasted.show(message, {
                  icon: 'chain-broken',
                  type: 'error'
                })
              }
              EventBus.$emit('countUpdated', {
                id: this.query.id,
                count: res.data.count
              })
            },
            () => {
              EventBus.$emit('countUpdated', { id: this.query.id, count: null })
            }
          )
      } else if (this.query.count !== null) {
        this.query.count = null
        this.resultCount = null
      }
    },
    fetchSiteResults() {
      this.$refs.siteKeyword.blur()
    },
    fetchContactResults() {
      this.$refs.contactKeyword.blur()
    },
    setOperatorDependants() {
      if (this.operatorObj) this.query.operator = this.operatorObj.id
      this.query.value = []
      this.query.count = null
      this.$emit('clearResult')
    },
    showSingleSelect() {
      return this.isBooleanOption()
    },
    isBooleanOption() {
      return (
        this.multiselectRuleOptions.length === 2 &&
        ((this.multiselectRuleOptions.includes('true') &&
          this.multiselectRuleOptions.includes('false')) ||
          (this.multiselectRuleOptions.includes(1) &&
            this.multiselectRuleOptions.includes(0)))
      )
    },
    showInstitutionsModal(queryID) {
      this.$emit('clearResult')
      this.$bvModal.show(`selectInstitutions_${queryID}`)
    },
    showUploadInstitutionsModal(queryID) {
      this.$emit('clearResult')
      this.$bvModal.show(`uploadInstitutions_${queryID}`)
    },
    showTreeModal() {
      switch (this.chosenRule.label) {
        case 'Technographics':
          this.$bvModal.show(`technographicsModal-${this.query.id}`)
          break
        default:
          break
      }
    },
    resetOperandDependants() {
      this.query.fitType = null
      this.query.childOperand = null
      this.query.operator = null
      this.query.count = null
      this.query.value = []
    },
    serviceUpdate(node) {
      this.resetOperandDependants()

      this.query.operand = node.data.id
      this.query.operandLabel = node.text

      this.query.operator = this.ruleOperators[0].id
      this.query.source = this.chosenOperandRule.source
      this.query.cuSource = this.chosenOperandRule.cu_source

      this.$emit('clearResult')
    },

    fiTypeUpdate(fiType) {
      this.query.fiType = fiType
    }
  },
  watch: {
    'query.operand': {
      handler: function() {
        if (this.isFirmographicsRule && this.query.operand)
          this.firmoOperand = this.chosenRule.children.find(
            child => child.id === this.query.operand
          )
      }
    },
    'query.ruleId': {
      handler: function() {
        if (this.query.ruleId) {
          this.ruleIdObj = this.baseOptionsForMBC.find(
            opt => opt.value == this.query.ruleId
          )
        } else {
          this.ruleIdObj = null
        }
      }
    },
    'query.operator': {
      handler: function() {
        if (this.query.operator) {
          this.operatorObj = this.ruleOperators.find(
            opt => opt.id == this.query.operator
          )
        } else {
          this.operatorObj = null
        }
      }
    },
    'query.value': {
      handler: function() {
        if (
          this.query.operator &&
          this.ruleForOptionType &&
          this.ruleForOptionType.type === 'select' &&
          this.showSingleSelect() &&
          this.query.value[0] != null
        ) {
          this.singleValueObj = this.ruleOptions.find(
            opt => opt.id == this.query.value[0]
          )
        }
      }
    },
    'query.source': {
      handler: function() {
        if (
          this.chosenRule &&
          this.chosenRule.label === 'Select Institutions'
        ) {
          if (this.query.source) {
            this.sourceObj = this.selectInstitutionOptions.find(
              opt => opt.value == this.query.source
            )
          } else {
            this.sourceObj = null
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-section {
  background-color: #e1f3fc;
}
.filter-depth {
  border-left: 3px solid #333333;
}
.rule-box-cnt {
  background-color: #f5f5f5;
}

.close-icon {
  margin-left: auto;
}

.rule-block,
.rule-box {
  width: 100%;
}

.rule-operator {
  margin-right: 0.5rem;
  min-width: 2rem;
  font-weight: bold;
}

.rule-count {
  margin-left: 0.5rem;
  min-width: 3.2rem;
  font-weight: bold;
}

.rule-error {
  border: solid 1px #dc3545;
  border-left: solid 1px #dc3545 !important;
  background: #fae3e3;
}

.clone-rule-icon {
  font-size: 1rem;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
.option-text {
  font-size: 0.875rem;
}
</style>

<style lang="scss">
.advanced-query-select {
  min-height: 33px;

  .multiselect__select {
    height: 33px;
  }

  .multiselect__tags {
    min-height: 33px;
    padding-top: 0.25rem;

    .multiselect__placeholder {
      margin-bottom: 0.25rem;
    }
  }
}
.simple-select.advanced-query-select {
  .multiselect__tags {
    .multiselect__placeholder {
      margin-bottom: 7px;
    }
  }
}
.advanced-query-select.eo-multi-select {
  .multiselect__select::before {
    border-width: 7px 7px 0;
  }
  .arrow {
    position: absolute;
    right: 0;
    padding-right: 1rem;
    padding-top: 0.35rem;
    .double-arrow {
      font-weight: 900;
    }
  }
  .multiselect__tags {
    padding-top: 0.35rem;
    .multiselect__placeholder {
      padding-top: 0px;
      margin-bottom: 6px;
    }
  }
}
.operand-select {
  max-width: 22rem;
}
</style>
