<template>
  <div>
    <b-card class="mx-2 std-border " header-class="p-2" body-class=" p-2">
      <div slot="header">
        <div class="row align-items-center pb-1 mb-2">
          <div class="col-sm-12 col-md-12 text-left">
            <h6 class="mb-0 d-inline-block font-weight-bold">
              <label for="operator" class="d-inline-block mb-0">
                Rule Filter Condition:
              </label>
            </h6>
            <multiselect
              v-model="filterOperatorObj"
              track-by="value"
              label="text"
              :options="filterOperatorOptions"
              :multiple="false"
              :close-on-select="true"
              :showLabels="false"
              placeholder="Select one"
              class="simple-select group-select form-control rounded advanced-query-select ml-2 mr-3"
              @close="criteriaChange()"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option-text">{{ props.option.text }}</span>
              </template>
            </multiselect>
            <!-- <select
              id="vqb-match-type"
              class="form-control-sm ml-2 mr-3"
              v-model="filterOperatorObj"
              @change="criteriaChange()"
            >
              <option selected>AND</option>
              <option>OR</option>
            </select> -->
            <h6 class="mb-0 d-inline-block">
              Financial institutions must pass
              <b class="underline-txt">{{
                query.filterOperator === 'AND' ? 'ALL' : 'at least ONE'
              }}</b>
              of the criteria in this group
            </h6>
          </div>
        </div>
      </div>
      <div>
        <div
          class="d-flex py-1"
          v-for="(child, index) in query.filters"
          :key="index"
        >
          <RuleFilter
            :filters="filters"
            :query="child"
            :rootQuery="rootQuery"
            :index="index"
            :groupOperator="query.filterOperator"
            :renderedIn="renderedIn"
            @updateQuery="updateQuery"
            @filterUpdated="filterUpdated"
            @cloneRule="cloneRule"
            @removeFilter="removeFilter"
            @clearResult="clearResult"
          ></RuleFilter>
        </div>
      </div>
      <div class="row">
        <div class="col-12 filter-group-modifier">
          <button
            type="button"
            class="btn btn-primary rounded mr-1"
            @click="addFilter()"
          >
            Add Filter
          </button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import deepClone from '@/utilities.js'
import Multiselect from 'vue-multiselect'
import Vue from 'vue'

export default {
  name: 'FilterGroup',
  components: {
    Multiselect,
    RuleFilter: () => import('./RuleFilter')
  },
  props: {
    filters: {
      type: Array
    },
    query: {
      type: Object
    },
    rootQuery: {
      type: Object
    },
    renderedIn: {
      type: String,
      default: 'Report'
    }
  },
  data() {
    return {
      filterOperatorObj: { text: 'AND', value: 'AND' },
      filterOperatorOptions: [
        { text: 'AND', value: 'AND' },
        { text: 'OR', value: 'OR' }
      ]
    }
  },
  mounted() {
    if (this.query.filterOperator) {
      this.filterOperatorObj = this.filterOperatorOptions.find(
        opt => opt.value == this.query.filterOperator
      )
    }
  },
  computed: {},
  methods: {
    addFilter() {
      this.$emit('addFilter')
      // let query = deepClone(this.query)
      // let filterId = this.generateNewId()
      // query.filters.push({
      //   id: filterId,
      //   ruleId: query.id,
      //   filterId: null,
      //   type: 'filter',
      //   operator: null,
      //   operand: null,
      //   childOperand: null,
      //   value: [],
      //   count: null,
      //   error: false,
      //   loading: false
      // })
      // //this.query = query
      // this.$emit('updateQuery', query)
    },

    criteriaChange() {
      this.query.filterOperator = this.filterOperatorObj.value
      let query = deepClone(this.query)
      this.$emit('filterUpdated', this.query)
      this.$emit('updateQuery', query)
    },
    updateQuery(newQuery) {
      let updateQuery = deepClone(this.query)
      let updateIndex = updateQuery.children.findIndex(
        q => q.id === newQuery.id
      )
      updateQuery.children[updateIndex] = newQuery
      this.$emit('updateQuery', updateQuery)
    },
    cloneRule(chosenRule) {
      let updateQuery = deepClone(this.query)
      let clonedRule = deepClone(chosenRule)
      let ruleIndex = updateQuery.children.findIndex(
        child => child.id === chosenRule.id
      )

      updateQuery.children.splice(ruleIndex + 1, 0, clonedRule)
      updateQuery.children.slice(ruleIndex + 1).forEach(child => {
        child.id = this.incrementedUniqId(child)
      })
      this.$emit('updateQuery', updateQuery)
    },
    clearResult() {
      this.$emit('clearResult')
    },
    removeFilter(uniqId) {
      if (this.query.filters.length === 1) {
        Vue.toasted.show('A Filter Group should have atleast one condition', {
          icon: 'chain-broken',
          type: 'error'
        })
        return
      }
      let updateQuery = deepClone(this.query)
      let ruleIndex = updateQuery.filters.findIndex(
        child => child.id === uniqId
      )
      updateQuery.filters.splice(ruleIndex, 1)
      this.$emit('updateQuery', updateQuery)
    },

    filterUpdated() {
      this.$emit('filterUpdated', this.query)
    },
    // generateNewId() {
    //   let len = this.query.filters.length
    //   if (len > 0) {
    //     let oldId = this.query.filters[len - 1].id.split('_').pop()
    //     let newId = parseInt(oldId) + 1
    //     return this.query.id + '_' + newId
    //   } else {
    //     return this.query.id + '_' + 1
    //   }
    // },
    incrementedUniqId(rule) {
      let oldUniqId = rule.id.split('_').pop()
      let newUniqId = parseInt(oldUniqId) + 1

      return this.query.id + '_' + newUniqId
    }
  },
  watch: {
    'query.filterOperator': {
      handler: function() {
        if (this.query.filterOperator) {
          this.filterOperatorObj = this.filterOperatorOptions.find(
            opt => opt.value == this.query.filterOperator
          )
        } else {
          this.filterOperatorObj = { label: 'AND', value: 'AND' }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.advanced-query-select {
  .option-text {
    font-size: 0.875rem;
  }
  .multiselect__placeholder {
    margin-bottom: 7px;
  }
}
</style>
<style lang="scss">
#query-tool {
  .underline-txt {
    text-decoration: underline;
  }

  .depth-1 {
    border-left: 3px solid #8bc34a;
  }

  .depth-2 {
    border-left: 3px solid #00bcd4;
  }

  .depth-3 {
    border-left: 3px solid #ff5722;
  }

  .depth-0 {
    border-left: 3px solid #c69500;
  }
  .filter-group-modifier {
    margin-left: 3.2rem;
  }
}
</style>
